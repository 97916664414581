import React, { memo, useState } from 'react';
import './Testcomp.css';

import { Portal, Toggle, Placeholder } from './helper/componentHelper';
import WindowSketch from './WindowSketch';
import WindowList, { SimpleWindowList } from './WindowList';
import PaneTypeSelection from './PaneTypeSelection';
import DimensionSelection from './DimensionSelection';
import useCopyToForm from '../hooks/useCopyToForm';

// memoized WindowList
// prevents WindowList from rerendering when there could be a change in selected grid
// only rerender when the id of the selected grid actually changes
const isSelectedGridEqual = (prevProps, nextProps) => {
  return prevProps?.selectedGrid?.id === nextProps?.selectedGrid?.id;
}

const MemoWindowList = memo(WindowList, isSelectedGridEqual);
const MemoSimpleWindowList = memo(SimpleWindowList, isSelectedGridEqual);

const WindowEditor = ({selectedGrid, setSelectedGrid}) => {
  console.log(selectedGrid);
  return(
    <div className="windowEditorContainer">
      <h1>Fenster Editor</h1>

      <PaneTypeSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
      <DimensionSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
      {/* <p style={{textAlign:"center"}}>{selectedGrid.name}</p> */}
      <div className="windowSketchContainer">
        <WindowSketch gridData={selectedGrid} labels={true} />
      </div>
    </div>
  )
}


const Testcomp = () => {
  const [selectedGrid, setSelectedGrid] = useState(null);
  console.log(selectedGrid);
  
  useCopyToForm(selectedGrid, setSelectedGrid);

/*   useEffect(() => {
    window.selectedGrid = selectedGrid;
  }, [selectedGrid]) */


  return (
    <React.Fragment>

      <Toggle title={"1. Fenstertyp"}>
        <MemoSimpleWindowList selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
        {/* <MemoWindowList selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} /> */}
      </Toggle>

      <Toggle title={"2. Öffnungstyp"}>
        {selectedGrid ? <PaneTypeSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} /> : <Placeholder />}
      </Toggle>

      <Toggle title={"3. Maße"}>
        {selectedGrid ? <DimensionSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} /> : <Placeholder />}

        {selectedGrid && <div className="windowSketchContainer windowSketchDimensionSelection">
          <WindowSketch gridData={selectedGrid} labels={true} ruler={true} />
        </div>}
      </Toggle>

      {/* add sketch to arbitrary place in actual dom through portal magic */}
      {selectedGrid && <Portal DOMNodeID="conf-sketch-container">
        <WindowSketch gridData={selectedGrid} labels={true} ruler={false} />
      </Portal>}
      


    </React.Fragment>
  );
};

export default Testcomp;