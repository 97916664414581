import './WindowSketch.css';
import React, { useContext } from 'react';
import _ from 'lodash';
import WindowGrid from './WindowGrid';
import { ThemeContext, themes } from '../context/themeContext';
import { GridContext } from '../context/gridContext';
import { Reect } from './helper/drawHelper';


function WindowGradients({ xStart, xEnd, yStart, yEnd }){
  const { theme } = useContext(ThemeContext);

  return (
    <defs>
      {/* gradientUnits userSpaceOnUse bounds the gradient to the entire thing, not only the object its applied to */}
      <linearGradient id="bgGradient" gradientUnits="userSpaceOnUse" x1="0%" y1="93%" x2="34%" y2="0%">
        <stop offset="0%" style={{ stopColor: theme.gradientStartColor, stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: theme.gradientStopColor, stopOpacity: 1 }} />
      </linearGradient>
    </defs>
  )
}

// WindowFrame draws the outer frame of the window
// 0 to totalwidth and 0 to totalheight 
// padding is the frame thickness
function WindowFrame(){
  const { theme: {frame} } = useContext(ThemeContext);
  const { gridData } = useContext(GridContext);

  const { innerBox, outerBox } = gridData; 

  return (
    <g>
      <Reect {...outerBox} strokeWidth={frame.strokeWidth} stroke={frame.strokeColor} fill={frame.frameFillColor} />
      <Reect {...innerBox} strokeWidth={frame.strokeWidth} stroke={frame.strokeColor} fill={frame.fillColor} />
    </g>
  )
}

function WindowSketch({ gridData, labels, ruler }) {
  const theme = themes.default;
  const { padding, edgeTickLength } = theme.ruler;


  const paneTicksOffset = edgeTickLength + (edgeTickLength * 2) * gridData.nRows;
  const singleTickOffset = edgeTickLength + (edgeTickLength * 2);

  // calculate exact offsets needed for marking
  const viewBoxStringRuler = `${0 - singleTickOffset} ${0 - paneTicksOffset} ${gridData.totalWidth + singleTickOffset + singleTickOffset} ${gridData.totalHeight + paneTicksOffset + singleTickOffset}`;
  const viewBoxString = `${0} ${0} ${gridData.totalWidth} ${gridData.totalHeight}`

  return (
    <GridContext.Provider value={{gridData}}>
      <ThemeContext.Provider value={{ theme, labels, ruler, toggleTheme: () => { console.log("not doing anything") } }}>
        <svg className={`sketch-svg ${ruler ? "sketch-ruler" : "sketch-no-ruler"}`} viewBox={ruler ? viewBoxStringRuler : viewBoxString} xmlns="http://www.w3.org/2000/svg">
          <WindowGradients xStart={0} xEnd={gridData.totalWidth} yStart={0} yEnd={gridData.totalHeight} />
          <WindowFrame />
          <WindowGrid gridData={gridData} />
        </svg>
      </ThemeContext.Provider>
    </GridContext.Provider>
  )
}

export default WindowSketch;