import React, { useEffect, useState } from 'react';
import _, { cloneDeep } from 'lodash';

import './PaneTypeSelection.css';

import WindowListItem from './WindowListItem';
import WindowSketch from './WindowSketch';
import typeCombinations from '../constants/typeCombinations';
import gridClass from './gridClass';
import { ToggleInput } from './helper/componentHelper';


import { useForm } from 'react-hook-form';
import * as PANETYPES from '../constants/paneTypes';
import { paneProperties } from '../constants/properties';



const setPaneType = (type, rowInd, paneInd, selectedGrid) => {
  // immutable variant
  /* return update(selectedGrid, { rows: { [rowInd]: { panes: { [paneInd]: { type: { $set: type } } } } } }) */
  // better crash on access than fail silently
  selectedGrid.rows[rowInd].panes[paneInd].type = type;
}

const getPaneType = (rowIndex, paneIndex, selectedGrid) => {
  return selectedGrid?.rows?.[rowIndex]?.panes?.[paneIndex]?.type;
}

// implement static panetype rules
const isValidPaneType = (rowIndex, paneIndex, type, selectedGrid) => {
  const leftNeighbour = getPaneType(rowIndex, paneIndex - 1, selectedGrid);
  const rightNeighbour = getPaneType(rowIndex, paneIndex + 1, selectedGrid);

  // everything not listed (PANETYPES.FIXED etc)
  // and not cought by the ifs 
  // will pass through to the default, which is true
  switch (type) {
    // allowed when
    // 1. No neighbour (single pane row)
    // 2. Two neighbours (three or more pane row, not on outside)
    // 3. One neighbour on the right (sits on the outside, should only face inwards)
    // or, to rephrase, not allowed when 
    // 1. One neighbour and that neighbour is on the left
    case PANETYPES.TYPE_TURN_TILT_LEFT:
    case PANETYPES.TYPE_TURN_LEFT_POST:
      if (!rightNeighbour && leftNeighbour) return false;
      return true;
    // same but mirrored
    case PANETYPES.TYPE_TURN_TILT_RIGHT:
    case PANETYPES.TYPE_TURN_RIGHT_POST:
      if (!leftNeighbour && rightNeighbour) return false;
      return true;
    // not valid when only left neighbour or no neighbours
    case PANETYPES.TYPE_TURN_LEFT_SASH:
    case PANETYPES.TYPE_TURN_TILT_LEFT_SASH:
      if ((!rightNeighbour && leftNeighbour) || (!rightNeighbour && !leftNeighbour)) return false;
      return true;
    // same but mirrored
    case PANETYPES.TYPE_TURN_RIGHT_SASH:
    case PANETYPES.TYPE_TURN_TILT_RIGHT_SASH:
      if ((!leftNeighbour && rightNeighbour) || (!rightNeighbour && !leftNeighbour)) return false;
      return true;
    default:
      return true;
  }
}

const SimpleTypeSelection = ({ selectedGrid, setSelectedGrid}) => {
  const { shapeHash } = selectedGrid;
  const combinations = typeCombinations[shapeHash];

  const windowCombination = combinations.map((config, index) => {
    const newGrid = new gridClass(selectedGrid.shape.join("-") + "-" + index, selectedGrid.shape);
    newGrid.generateDefaultFromShape();
    config.forEach((row,rowIndex) => {
      row.forEach((paneType,paneIndex) => {
        newGrid.rows[rowIndex].panes[paneIndex].type = paneType;
      })
    })
    return newGrid;
  });

  return(
    <div className="windowListGroupContainer">
      <div className="windowListGroup">
        {windowCombination.map((gridData) => {
          return <WindowListItem key={gridData.id} title={gridData.paneTypesName} labels={true} scale={1.4} gridData={gridData} selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
        })}
      </div>
    </div>
  )
}


const AdvancedTypeSelection = ({selectedGrid, setSelectedGrid}) => {
  const { register, setValue, reset } = useForm();
  // generate all valid options
  // based on this, the select fields are supplied with their options
  const validOptions = selectedGrid?.rows.map((row, rowInd) => {
    return row.panes.map((pane, paneInd) => {
      let types = [];
      for (let TYPENAME in PANETYPES) {
        const type = PANETYPES[TYPENAME];
        // add the type to the array of valid types if its valid
        if (isValidPaneType(rowInd, paneInd, type, selectedGrid)) types.push(type);
      }
      return types;
    })
  });


  function handleSelection(e, rowInd, paneInd, selectedGridRef, depth = 2) {
    // hotfix since apparently you cant access e in the anonymous function of onChange
    const paneType = e?.target?.value || e;

    // this needs to happen to allow for multiple changes
    // inside this method, selectedGrid will always be in the state before this cycle
    // i.e. 
    // console.log(selectedgrid) -> "something"
    // setSelectedGrid("something else");
    // console.log(selectedgrid) -> "something"
    // changes are accumulated in tempGrid and then submitted via setSelectedGrid
    // clone deep ensures selectedGrid is not mutated -> but only when not called within itself
    let tempGrid = selectedGridRef || cloneDeep(selectedGrid);

    const currentPane = getPaneType(rowInd, paneInd, tempGrid);
    const leftNeighbour = getPaneType(rowInd, paneInd - 1, tempGrid);
    const rightNeighbour = getPaneType(rowInd, paneInd + 1, tempGrid);

    // enforce the dynamic rules:
    // two cases:
    // a pane is set to stash
    // - set this pane to sash
    // - set the opposite pane to turn tilt 
    // - - sash left <-> turn tilt right on paneIndex + 1
    // - - sash right <-> turn tilt left on paneIndex - 1
    // a pane is not set to turn tilt
    // - set this pane to sash
    // - if the opposite pane is type sash, set it to fixed casement/default
    // - - !turn tilt left <-> sash right -> fixed casement
    // - - !turn tilt right <-> sash left -> fixed casement

    // order is important 
    // pane set to sash need to come after pane is not set to turn tilt
    // if a pane was turn tilt and is set to stash, the neighbour would be overwritten with by the pane is not set to turn tilt rule

    if (depth > 0) {

      if (paneType !== PANETYPES.TYPE_TURN_LEFT_SASH && paneType !== PANETYPES.TYPE_TURN_TILT_LEFT_SASH) {
        if (currentPane === PANETYPES.TYPE_TURN_LEFT_SASH || currentPane === PANETYPES.TYPE_TURN_TILT_LEFT_SASH) {
          setValue(`selectedPaneTypes[${rowInd}][${paneInd + 1}]`, PANETYPES.TYPE_FIXED_CASEMENT);
          handleSelection(PANETYPES.TYPE_FIXED_CASEMENT, rowInd, paneInd + 1, tempGrid, depth - 1);
        }
      }

      if (paneType !== PANETYPES.TYPE_TURN_RIGHT_SASH && paneType !== PANETYPES.TYPE_TURN_TILT_RIGHT_SASH) {
        if (currentPane === PANETYPES.TYPE_TURN_RIGHT_SASH || currentPane === PANETYPES.TYPE_TURN_TILT_RIGHT_SASH) {
          setValue(`selectedPaneTypes[${rowInd}][${paneInd - 1}]`, PANETYPES.TYPE_FIXED_CASEMENT);
          handleSelection(PANETYPES.TYPE_FIXED_CASEMENT, rowInd, paneInd - 1, tempGrid, depth - 1);
        }
      }

      if (paneType === PANETYPES.TYPE_TURN_LEFT_SASH) {
        setValue(`selectedPaneTypes[${rowInd}][${paneInd + 1}]`, PANETYPES.TYPE_TURN_TILT_RIGHT_SASH);
        handleSelection(PANETYPES.TYPE_TURN_TILT_RIGHT_SASH, rowInd, paneInd + 1, tempGrid, depth - 1);
      }

      if (paneType === PANETYPES.TYPE_TURN_TILT_LEFT_SASH) {
        setValue(`selectedPaneTypes[${rowInd}][${paneInd + 1}]`, PANETYPES.TYPE_TURN_RIGHT_SASH);
        handleSelection(PANETYPES.TYPE_TURN_RIGHT_SASH, rowInd, paneInd + 1, tempGrid, depth - 1);
      }

      if (paneType === PANETYPES.TYPE_TURN_RIGHT_SASH) {
        setValue(`selectedPaneTypes[${rowInd}][${paneInd - 1}]`, PANETYPES.TYPE_TURN_TILT_LEFT_SASH);
        handleSelection(PANETYPES.TYPE_TURN_TILT_LEFT_SASH, rowInd, paneInd - 1, tempGrid, depth - 1);
      }

      if (paneType === PANETYPES.TYPE_TURN_TILT_RIGHT_SASH) {
        setValue(`selectedPaneTypes[${rowInd}][${paneInd - 1}]`, PANETYPES.TYPE_TURN_LEFT_SASH);
        handleSelection(PANETYPES.TYPE_TURN_LEFT_SASH, rowInd, paneInd - 1, tempGrid, depth - 1);
      }
    }

    setPaneType(paneType, rowInd, paneInd, tempGrid)

    setSelectedGrid(() => { return tempGrid });
  }

  // deeply nested bullshit
  return (
    <div className="formContainer">
      {validOptions.map((optionRow, rowInd) => {
        return (
          <div>
            <span className="formGroupTitle">{selectedGrid.rows[rowInd].name}</span>
            <div className="formRow" key={rowInd}>
              {optionRow.map((optionPane, paneInd) => (
                <div className="formGroup" key={rowInd.toString() + "_" + paneInd.toString()}>
                  <label>{selectedGrid.rows[rowInd].panes[paneInd].name}</label>
                  <select
                    name={`selectedPaneTypes[${rowInd}][${paneInd}]`}
                    value={selectedGrid.rows[rowInd].panes[paneInd].type}
                    onChange={(e) => { handleSelection(e, rowInd, paneInd) }}
                    ref={register()}
                  >
                    {optionPane.map((option, optionInd) => (
                      <option key={option} value={option}>{paneProperties[option].name}</option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const PaneTypeSelection = ({ selectedGrid, setSelectedGrid }) => {
  const templatesAvailable = selectedGrid.shapeHash in typeCombinations;
  const [bAdvanced, setbAdvanced] = useState(false);

  const handleChange = () => {
    setbAdvanced(!bAdvanced);
  }

  useEffect(() => {
    setbAdvanced(!templatesAvailable)
  },[templatesAvailable])

  return (
    <>
      <div className="filterContainer">
        <h3>Modus</h3>
        <ToggleInput checked={!bAdvanced} handleChange={handleChange} disabled={!templatesAvailable} title={`${!bAdvanced ? 'Vorlage' : `Freie Auswahl ${!templatesAvailable ? '(Keine Vorlagen Verfügbar)' : ''}`}`} />
      </div>
      <h3>Auswahl</h3>
      {/* TODO(Jan): pretty dumb solution, do something that is at least a little prettier */}
      {!bAdvanced && templatesAvailable && <SimpleTypeSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />}
      {bAdvanced && 
        <>
          <AdvancedTypeSelection selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
          <div className="windowSketchContainer windowSketchPaneTypeSelection">
            <WindowSketch gridData={selectedGrid} labels={true} ruler={false} />
          </div>
        </>
      }
    </>
  )
}

export default PaneTypeSelection;