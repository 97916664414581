import React, { useContext, useRef, useState } from 'react';
import _, { pad } from 'lodash';
import { ThemeContext } from '../context/themeContext';
import * as PANETYPES from '../constants/paneTypes';
import { GridContext } from '../context/gridContext';
import { Reect, Ruler } from './helper/drawHelper';


function WindowPane({pane}) {
  const { theme, labels, ruler } = useContext(ThemeContext);
  const { gridData } = useContext(GridContext);
  const { typeWidth, typeColor, typeCrosshairLength} = theme.pane;
  const { handlePadding, baseWidth, baseHeight, handleStrokeColor, handleFillColor, handleWidth, handleHeight} = theme.handle;
  const { type, xStart, xEnd, yStart, yEnd, width, height, center, outerBox, innerBox, margin, padding, innerWidth, innerHeight, innerCenter, outerHeight, outerWidth, outerCenter } = pane;

  // all the type-dependent markings (red lines)
  const crosshair = <React.Fragment>
    <line x1={innerCenter.x - (typeCrosshairLength / 2)} x2={innerCenter.x + (typeCrosshairLength / 2)} y1={innerCenter.y} y2={innerCenter.y} stroke={typeColor} strokeWidth={typeWidth} />
    <line x1={innerCenter.x} x2={innerCenter.x} y1={innerCenter.y - (typeCrosshairLength / 2)} y2={innerCenter.y + (typeCrosshairLength / 2)} stroke={typeColor} strokeWidth={typeWidth} />
  </React.Fragment>
    
  const rectSides = <Reect xStart={innerBox.xStart + typeWidth} xEnd={innerBox.xEnd - typeWidth} yStart={innerBox.yStart + typeWidth} yEnd={innerBox.yEnd - typeWidth} stroke={typeColor} strokeWidth={typeWidth} fillOpacity="0" />;

  const trigTop = <React.Fragment>
    <line x1={innerBox.xStart + typeWidth} x2={innerBox.xStart + (innerWidth / 2) - typeWidth} y1={innerBox.yStart + innerHeight - typeWidth} y2={innerBox.yStart + typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
    <line x1={innerBox.xStart + innerWidth - typeWidth} x2={innerBox.xStart + (innerWidth / 2) - typeWidth} y1={innerBox.yStart + innerHeight - typeWidth} y2={innerBox.yStart + typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
  </React.Fragment>
  
  const trigLeft = <React.Fragment>
    <line x1={innerBox.xStart + typeWidth} x2={innerBox.xStart + innerWidth - typeWidth} y1={innerBox.yStart + typeWidth} y2={innerBox.yStart + (innerHeight / 2) - typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
    <line x1={innerBox.xStart + typeWidth} x2={innerBox.xStart + innerWidth - typeWidth} y1={innerBox.yStart + innerHeight - typeWidth} y2={innerBox.yStart + (innerHeight / 2) - typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
  </React.Fragment>
  
  const trigRight = <React.Fragment>
    <line x1={innerBox.xStart + innerWidth - typeWidth} x2={innerBox.xStart + typeWidth} y1={innerBox.yStart + typeWidth} y2={innerBox.yStart + (innerHeight / 2) - typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
    <line x1={innerBox.xStart + innerWidth - typeWidth} x2={innerBox.xStart + typeWidth} y1={innerBox.yStart + innerHeight - typeWidth} y2={innerBox.yStart + (innerHeight / 2) - typeWidth} stroke={typeColor} strokeWidth={typeWidth} />
  </React.Fragment>

  // vertical, dashed line indicating the sash
  // is part of the -turn-sash type, not -turn-tilt-sash type
  // -> both turn-tilt-sash + opposite turn-sash need to have the same margin (i.e. "post" thickness)
  const sashLeft = <line x1={outerBox.xStart + outerWidth + (margin.right)} x2={outerBox.xStart + outerWidth + (margin.right)} y1={outerBox.yStart} y2={outerBox.yStart + outerHeight} stroke={typeColor} strokeWidth={margin.right * 2} strokeDasharray={theme.pane.sashDashArray} />
  const sashRight = <line x1={outerBox.xStart - (margin.left)} x2={outerBox.xStart - (margin.left)} y1={outerBox.yStart} y2={outerBox.yStart + outerHeight} stroke={typeColor} strokeWidth={margin.left * 2} strokeDasharray={theme.pane.sashDashArray} />

  const handleLeft = <React.Fragment>
    <Reect xStart={outerBox.xStart + handlePadding} xEnd={outerBox.xStart + handlePadding + baseWidth} yStart={center.y - (baseHeight / 2)} yEnd={center.y + (baseHeight / 2)} stroke={handleStrokeColor} fill={handleFillColor} />
    <Reect xStart={outerBox.xStart + handlePadding + (baseWidth / 2) - (handleWidth / 2)} xEnd={outerBox.xStart + handlePadding + (baseWidth / 2) + (handleWidth / 2)} yStart={center.y - (handleWidth / 2)} yEnd={center.y - (handleWidth / 2) + handleHeight} stroke={handleStrokeColor} fill={handleFillColor} />
  </React.Fragment>

  const handleRight = <React.Fragment>
    <Reect xStart={outerBox.xEnd - handlePadding - baseWidth} xEnd={outerBox.xEnd - handlePadding} yStart={center.y - (baseHeight / 2)} yEnd={center.y + (baseHeight / 2)} stroke={handleStrokeColor} fill={handleFillColor} />
    <Reect xStart={outerBox.xEnd - handlePadding - (baseWidth / 2) - (handleWidth / 2)} xEnd={outerBox.xEnd - handlePadding - (baseWidth / 2) + (handleWidth / 2)} yStart={center.y - (handleWidth / 2)} yEnd={center.y - (handleWidth / 2) + handleHeight} stroke={handleStrokeColor} fill={handleFillColor} />
  </React.Fragment>

  const handleTop = <React.Fragment>
    <Reect xStart={center.x - (baseHeight / 2)} xEnd={center.x + (baseHeight / 2)} yStart={outerBox.yStart + handlePadding} yEnd={outerBox.yStart + handlePadding + baseWidth} stroke={handleStrokeColor} fill={handleFillColor} />
    <Reect xStart={center.x - (handleWidth / 2)} xEnd={center.x - (handleWidth / 2) + handleHeight} yStart={outerBox.yStart + handlePadding + (baseWidth / 2) - (handleWidth / 2)} yEnd={outerBox.yStart + handlePadding + (baseWidth / 2) + (handleWidth / 2)} stroke={handleStrokeColor} fill={handleFillColor} />
  </React.Fragment>

  const renderPaneTypes = () => {
    switch(type){
      case PANETYPES.TYPE_TILT:
        return (
          <React.Fragment>
            {rectSides}
            {trigTop}
            {handleTop}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_TILT_LEFT:
      case PANETYPES.TYPE_TURN_TILT_LEFT_SASH:
        return (
          <React.Fragment>
            {rectSides}
            {trigTop}
            {trigLeft}
            {handleRight}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_TILT_RIGHT:
      case PANETYPES.TYPE_TURN_TILT_RIGHT_SASH:
        return (
          <React.Fragment>
            {rectSides}
            {trigTop}
            {trigRight}
            {handleLeft}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_LEFT_POST:
        return (
          <React.Fragment>
            {rectSides}
            {trigLeft}
            {handleRight}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_RIGHT_POST:
        return (
          <React.Fragment>
            {rectSides}
            {trigRight}
            {handleLeft}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_LEFT_SASH:
        return (
          <React.Fragment>
            {rectSides}
            {trigLeft}
            {sashLeft}
          </React.Fragment>
        )
      case PANETYPES.TYPE_TURN_RIGHT_SASH:
        return (
          <React.Fragment>
            {rectSides}
            {trigRight}
            {sashRight}
          </React.Fragment>
        )
      case PANETYPES.TYPE_FIXED_CASEMENT:
      case PANETYPES.TYPE_FIXED:  
      default:
        return (
          <React.Fragment>
            {crosshair}
          </React.Fragment>
        )
    }
  }

  const renderPaneBase = () => (
    <React.Fragment>
      <Reect {...outerBox} strokeWidth={theme.pane.strokeWidth} stroke={theme.pane.strokeColor} fill={theme.pane.frameFillColor} />
      <Reect {...innerBox} strokeWidth={theme.pane.strokeWidth} stroke={theme.pane.strokeColor} fill="url(#bgGradient)" />
    </React.Fragment>
  )

  const renderLabels = () => (
    <React.Fragment>
      <text {...innerCenter} dominantBaseline="middle" textAnchor="middle" fontSize="2em">
        {innerWidth} mm x {innerHeight} mm
      </text>
      <text x={innerBox.xEnd} y={innerBox.yStart} dominantBaseline="hanging" textAnchor="end" fontSize="1.5em">
        {outerWidth} mm x {outerHeight} mm
      </text>
      <text x={innerBox.xStart} y={innerBox.yStart} dominantBaseline="hanging" fontSize="1.5em">
        {pane.name}
      </text>
    </React.Fragment>
  )

  const renderInnerMeasurements = () => (
    <React.Fragment>
      {/* inner measurements */}
      <Ruler x={innerCenter.x} y={innerBox.yEnd - 100} length={innerWidth} rotation={0} textOffsetPercent={0.3} >
        {_.round(innerWidth, 2)} mm
      </Ruler>
      <Ruler x={innerBox.xStart + 100} y={innerCenter.y} length={innerHeight} rotation={-90} textOffsetPercent={0.5} >
        {_.round(innerHeight, 2)} mm
      </Ruler>
      {/* outer measurements */}
      <Ruler x={outerCenter.x} y={outerBox.yEnd - 25} length={outerWidth} rotation={0} textOffsetPercent={0.3} >
        {_.round(outerWidth, 2)} mm
      </Ruler>
      <Ruler x={outerBox.xStart + 25} y={outerCenter.y} length={outerHeight} rotation={-90} textOffsetPercent={0.5} >
        {_.round(outerHeight, 2)} mm
      </Ruler>
    </React.Fragment>
  )

  const renderOuterMeasurements = () => {
    const {topNeighbourCount: tnc, bottomNeighbourCount: bnc} = pane;

    const { padding, edgeTickLength } = theme.ruler;
/*     const yPosition = tnc <= bnc ? - offset * (tnc + 1) : yEnd + offset * (bnc + 1); */
    const yPosition = (0 - edgeTickLength) - (edgeTickLength * 2/*  + padding */) * bnc;

    return (
      <React.Fragment>
        {/* total width */}
        <Ruler x={center.x} y={yPosition} length={width} rotation={0} textOffsetPercent={0.5} >
          {_.round(width, 2)} mm
        </Ruler>
      </React.Fragment>
    )
  }

  return (
    <g>
      <title>
        {pane.parent.name} - 
        Scheiben Nr. {pane.paneIndex + 1} -
        Größe: {width} mm x {height} -
        Größe (Rahmen): {outerWidth} mm x {outerHeight} mm -
        Größe (Glas): {innerWidth} mm x {innerHeight} mm
      </title>

      {renderPaneBase()}
      {labels && renderPaneTypes()}
      {/* labels && renderLabels() */}
      {ruler && renderInnerMeasurements()}
      {ruler && renderOuterMeasurements()}
    </g>
  )
}

function WindowRow({row, children}) {
  const { theme, labels, ruler } = useContext(ThemeContext);
  const { padding, edgeTickLength } = theme.ruler;

  const renderOuterMeasurements = () => {
    const xPosition = row.parent.totalWidth + edgeTickLength;
    return (
      <React.Fragment>
        <Ruler x={xPosition} y={row.center.y} length={row.height} rotation={90} >
          {_.round(row.height,2)} mm
        </Ruler>
      </React.Fragment>
    )
  }

  return (
    <g>
      {children}
      {ruler && renderOuterMeasurements()}
    </g>
  )
}

function WindowGrid({ gridData }) {
  const { theme, labels, ruler } = useContext(ThemeContext);
  const { padding, edgeTickLength } = theme.ruler;
  const {totalWidth, totalHeight} = gridData;

  const renderOuterMeasurements = () => {

    return(
      <React.Fragment>
        <Ruler x={0 - edgeTickLength} y={totalHeight / 2} length={totalHeight} rotation={-90} >
          Gesamte Höhe: {_.round(totalHeight, 2)} mm
        </Ruler>
        <Ruler x={totalWidth / 2} y={totalHeight + edgeTickLength} length={totalWidth} rotation={0} >
          Gesamte Breite: {_.round(totalWidth)} mm
        </Ruler>
      </React.Fragment>
    )
  }

  return (
    <g>
      <title>Layer 1</title>
      {gridData.rows.map((row, rowInd) => (
        <WindowRow key={`row_${rowInd}`} row={row} >
          {row.panes.map((pane, paneInd) => (
            <WindowPane key={`pane_${rowInd}_${paneInd}`} pane={pane} />
          ))}
        </WindowRow>
      ))}
      {ruler && renderOuterMeasurements()}
    </g>
  )
}

export default WindowGrid;