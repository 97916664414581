import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import WindowListItem from './WindowListItem';
import { RadioInput } from './helper/componentHelper';
import gridClass from './gridClass';

const generateCombinations = () => {
  const oberlicht = [0, 1, 2, 3];
  const hauptfenster = [1, 2, 3];
  const unterlicht = [0, 1, 2, 3];

  let combinations = [];

  for (let ol of oberlicht) {
    for (let hf of hauptfenster) {
      for (let ul of unterlicht) {
        combinations.push([ol, hf, ul]);
      }
    }
  }

  return combinations;
}

const generateCombinationsTemplate = ([oberlicht, hauptfenster, unterlicht]) => {
  let combinations = [];

  for (let hf of hauptfenster) {
    for (let ol of oberlicht) {
      for (let ul of unterlicht) {
        combinations.push([ol, hf, ul]);
      }
    }
  }

  return combinations;
}

const simpleCombs = [[0], [1, 2, 3], [0]];
const oberlichtCombs = [[1, 2, 3], [1, 2, 3], [0]];
const unterlichtCombs = [[0], [1, 2, 3], [1, 2, 3]];
const oberUnterlichtCombs = [[1, 2, 3], [1, 2, 3], [1, 2, 3]];

const combinationTemplates = [
  { name: "Einfach", combinations: simpleCombs },
  { name: "Oberlicht", combinations: oberlichtCombs },
  { name: "Unterlicht", combinations: unterlichtCombs },
  { name: "Ober- und Unterlicht", combinations: oberUnterlichtCombs },
];

const groupedSketchDataArray = combinationTemplates.map((template) => {
  const combinations =  generateCombinationsTemplate(template.combinations);
  const groupedCombinations = _.groupBy(combinations, (o) => o[1]);

  const groupedSketchData = _.map(groupedCombinations, (group, groupIndex) => {
    return group.map((shape, index) => {
      const newGrid = new gridClass(shape.join("-"), shape);
      newGrid.generateDefaultFromShape();
      return newGrid;
    })
  });

  return groupedSketchData;
});

const SimpleWindowList = ({ selectedGrid, setSelectedGrid }) => {
  const [selectedFilter, setSelectedFilter] = useState(0);

  const handleChange = (ind) => {
    setSelectedFilter(ind);
  }

  // setting a default grid
  // only if no grid is set before (i.e. from restoring cpo data)
  useEffect(() => {
    if(!selectedGrid) setSelectedGrid(groupedSketchDataArray[0][0][0]);
  },[])

  return (
    <>
      <div className="filterContainer">
        <h3>Filter</h3>
        <div className="radioGroup">
          {combinationTemplates.map((value, ind) => (
            <RadioInput key={ind} value={ind} ind={ind} checked={selectedFilter === ind} onChange={() => { handleChange(ind) }}>{value.name}</RadioInput>
          ))}
        </div>
      </div>
      <h3>Auswahl</h3>
      {_.map(groupedSketchDataArray[selectedFilter], (sketchDataGroup, ind) => {
        return (
          <div key={ind} className="windowListGroupContainer">
            {/*             <h2>{ind}-Teilig</h2> */}
            <div className="windowListGroup">
              {sketchDataGroup.map((gridData) => {
                return <WindowListItem key={gridData.id} gridData={gridData} title={gridData.name} selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
              })}
            </div>
          </div>)
      })}
    </>
  )
}



// generate and display all the different window shape combinations
// TODO(Jan): Needs to be optimized, maybe here, maybe the Window sketch
const WindowList = ({ selectedGrid, setSelectedGrid }) => {
  const { register, watch } = useForm();
  // get all combinations from oberlicht, hauptfenster and unterlicht
  const combinations = generateCombinations();

  // generate the coordinates from all the different combinations of shapes
  //const sketchData = generateSketchData(combinations);
  /*   const sketchData = combinations.map((shape, index) => {
      const newGrid = new gridClass(index,shape);
      newGrid.generateDefaultFromShape();
      return newGrid;
    })
   */
  const sketchData = useMemo(() => {
    return combinations.map((shape, index) => {
      const newGrid = new gridClass(index, shape);
      newGrid.generateDefaultFromShape();
      return newGrid;
    })
  }, [combinations])

  console.log("regenerating everything");

  // filter the generated sketches by the chckboxes
  const bOberlichtSelected = watch("bOberlichtSelected")
  const bUnterlichtSelected = watch("bUnterlichtSelected")
  const filteredSketchData = sketchData.filter(({ shape }) => {
    const bOberlicht = shape[0] > 0;
    const bUnterlicht = shape[2] > 0;
    // only let windows with oberlicht oder unterlicht through if the respective checkbox is checked
    // some janky bools
    return !(bUnterlicht && !(bUnterlichtSelected) || bOberlicht && !(bOberlichtSelected));
  })

  // sort by number of panes 1. in HF, 2. OL, 3. UL
  // TODO(Jan): Check if more intuitive way to order exists
  const sortedSketchData = _.sortBy(filteredSketchData, [(o) => (o.shape[1]), (o) => (o.shape[0]), (o) => (o.shape[2])])
  // Group by number of panes in Hauptfenster
  const groupedSketchData = _.groupBy(sortedSketchData, (o) => (o.shape[1]))

  return (
    <>
      <h3>Filter</h3>
      <div>
        <label>Oberlicht </label>
        <input type="checkbox" name="bOberlichtSelected" defaultValue={false} ref={register} />
      </div>
      <div className="">
        <label>Unterlicht </label>
        <input type="checkbox" name="bUnterlichtSelected" defaultValue={false} ref={register} />
      </div>

      {_.map(groupedSketchData, (sketchDataGroup, ind) => {
        return (
          <div key={ind} className="windowListGroupContainer">
            {/*             <h2>{ind}-Teilig</h2> */}
            <div className="windowListGroup">
              {sketchDataGroup.map((gridData) => {
                return <WindowListItem key={gridData.id} gridData={gridData} selectedGrid={selectedGrid} setSelectedGrid={setSelectedGrid} />
              })}
            </div>
          </div>)
      })}
    </>
  )
}

export { SimpleWindowList }
export default WindowList;