export const TYPE_FIXED = "f";
export const TYPE_FIXED_CASEMENT =  "ff";
export const TYPE_TILT =  "k";
export const TYPE_TURN_TILT_LEFT =  "dkl";
export const TYPE_TURN_TILT_LEFT_SASH = "dkls";
export const TYPE_TURN_LEFT_POST = "dlp";
export const TYPE_TURN_LEFT_SASH = "dls";
export const TYPE_TURN_TILT_RIGHT =  "dkr";
export const TYPE_TURN_TILT_RIGHT_SASH = "dkrs"
export const TYPE_TURN_RIGHT_POST = "drp";
export const TYPE_TURN_RIGHT_SASH = "drs";
