import _, { debounce } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import useCopyFromForm from './useCopyFromForm';
import { getPanecpoFieldId, getcpoFieldId, panePropsToCopy } from '../components/helper/unicpoHelper';

function useCopyToForm(selectedGrid, setSelectedGrid) {

  const [hasChecked] = useCopyFromForm(selectedGrid, setSelectedGrid)
  
  // !!! sicherstellen, dass nach der letzten Änderung eine erfolgreiche Übertragung zu unicpo stattgefunden hat!!!
  // allgemein: Sicherstellen, dass react und unicpo nicht out of sync sind bei bestell-abgabe

  // debouncing the event firing which trigger the unicpo price recalculation (which is sloooow)
  // see https://dmitripavlutin.com/react-throttle-debounce/
  // basically: debouce delays invocation until delay has elapsed since the last time it was called
  // this only works if the instance of the functioncall is the same 
  // so: useMemo memoizes the debounced function but also calls debounce only initial rendering
  const debounceEventFire = useMemo(() => debounce(() => {
    const nameInput = document.getElementById(getcpoFieldId("name"));
    nameInput.dispatchEvent(new Event('change', { bubbles: true }))
  }, 500),[])

  useEffect(() => {
    if(!hasChecked || !window.jQuery || !selectedGrid || !selectedGrid.flattendPanes) return;

    const nameInput = document.getElementById(getcpoFieldId("name"));
    if(nameInput) nameInput.value = selectedGrid.name;

    const shapeInput = document.getElementById(getcpoFieldId("shape"));
    if (shapeInput) shapeInput.value = selectedGrid.shapeHash;

    const idInput = document.getElementById(getcpoFieldId("id"));
    if(idInput) idInput.value = selectedGrid.id;

    const heightInput = document.getElementById(getcpoFieldId("height_total"));
    if(heightInput) heightInput.value = _.round(selectedGrid.totalHeight, 2);

    const widthInput = document.getElementById(getcpoFieldId("width_total"));
    if(widthInput) widthInput.value = _.round(selectedGrid.totalWidth, 2); 

    const nPanesInput = document.getElementById(getcpoFieldId("n_panes"));
    if(nPanesInput) nPanesInput.value = selectedGrid.nPanes;

    const nPanesOpenableInput = document.getElementById(getcpoFieldId("n_panes_openable"));
    if (nPanesOpenableInput) nPanesOpenableInput.value = selectedGrid.nPanesOpenable;


    // encode the sketch data into base64 and add to input
    const svg = document.querySelector("#conf-sketch-container .sketch-svg");
    const testinput = document.getElementById(getcpoFieldId("sketch_data"));
    if (testinput) testinput.value = btoa(svg.outerHTML);

    // encode the grid data into base64 and add to input
    const objectInput = document.getElementById(getcpoFieldId("object_data"));
    // remove circular structure from object -> panes link to parent which again link to the pane
    const objectString = JSON.stringify(selectedGrid, (key, val) => (key === 'parent') ? undefined : val)
    if (objectInput) objectInput.value = btoa(objectString);

    selectedGrid.flattendPanes.forEach((pane, ind) => {
      panePropsToCopy.forEach((prop) => {
        const input = document.getElementById(getPanecpoFieldId(prop, ind));

        if(input && pane[prop]) {
          /* setNativeValue(input, pane[prop]); */
          input.value = typeof(pane[prop]) === "number" ? _.round(pane[prop], 2) : pane[prop];
        }
      })
    });

    debounceEventFire();

    // parsley (unicpo form validation library) relies on jquery.............
    // this triggers validation after entering all the inputs
    // not really necessary i think since validation already happens in the form here
    window.jQuery('form.cart').parsley().validate();
  }, [hasChecked, selectedGrid]);
}

export default useCopyToForm;