import React, { useContext, useEffect,  useRef, useState } from 'react';
import { ThemeContext } from '../../context/themeContext';


export const Reect = ({ xStart, xEnd, yStart, yEnd, ...rest }) => (
  <rect x={xStart} y={yStart} width={xEnd - xStart} height={yEnd - yStart} {...rest} />
)


export const useBBox = (dependencyArray) => {
  const ref = useRef();
  const [bBox, setBBox] = useState({});

  useEffect(() => {
    const currentBox = ref && ref.current ? ref.current.getBBox() : {}
    setBBox(currentBox);
  }, dependencyArray)

  return [bBox, ref];
}

export const Ruler = ({x,y,length, rotation = 0, textOffsetPercent = 0.5, color, children, ...rest}) => {
  const [bBox, ref] = useBBox([x,y,length,rotation]);

  const { theme: {ruler} } = useContext(ThemeContext);
  const {contentPadding, fallbackContentWidth, edgeTickLength, stroke, fontSize, strokeWidth, precision} = ruler;

  // textOffsetPercent = 1 -> all the way at the end
  // textOffsetPercent = 0 -> all the way at the beginning
  // textOffsetPercent = 0.5 -> in the middle
  const textOffset = (textOffsetPercent - 0.5) * length 

  const leftEdge = x + (strokeWidth / 2) - length / 2;
  // HACK(Jan): fall back to default content width when the bounding box from the ref isn't available yet 
  // this is only required since we grab the svg content in a hook that uses selectedGrid as dependency
  // bBox changes, selectedGrid not -> svg is not yet valid/valid only on the next render
  const contentLeftEdge = x + textOffset - contentPadding - (bBox.width || fallbackContentWidth) / 2;
  const contentRightEdge = x + textOffset + contentPadding + (bBox.width || fallbackContentWidth) / 2;
  const rightEdge = x - (strokeWidth / 2) + length / 2;

  return (
    <g className="ruler">
      <line x1={leftEdge} y1={y} x2={contentLeftEdge} y2={y} transform={`rotate(${rotation},${x},${y})`} stroke={color || stroke} strokeWidth={strokeWidth} />
      <line x1={contentRightEdge} y1={y} x2={rightEdge} y2={y} transform={`rotate(${rotation},${x},${y})`} stroke={color || stroke} strokeWidth={strokeWidth} />

      <line x1={leftEdge} y1={y - edgeTickLength} x2={leftEdge} y2={y + edgeTickLength} transform={`rotate(${rotation},${x},${y})`} stroke={color || stroke} strokeWidth={strokeWidth} />
      <line x1={rightEdge} y1={y - edgeTickLength} x2={rightEdge} y2={y + edgeTickLength} transform={`rotate(${rotation},${x},${y})`} stroke={color || stroke} strokeWidth={strokeWidth} />
      <text ref={ref} x={x + textOffset} y={y} transform={`rotate(${rotation},${x},${y})`} dominantBaseline="middle" textAnchor="middle" fontSize={fontSize} fill={color || stroke} >
        {children}
      </text>
    </g>
  );
};