import { createContext } from 'react';

export const themes = {
  default: {
    gradientStartColor: 'rgb(233,245,245)',
    gradientStopColor: 'rgb(233,245,245)',
    frame: {
      strokeWidth: 1.5,
      strokeColor: '#4a4a4a',
      frameFillColor: '#707070',
      fillColor: '#999999',
    },
    pane: {
      strokeWidth: 1.5,
      strokeColor: '#4a4a4a',
      frameFillColor: '#b0b0b0',
      typeColor: '#ff0000',
      typeWidth: 1,
      typeCrosshairLength: 200,
      sashDashArray: "1% 1%",
    },
    handle:{
      handlePadding: 20,
      baseWidth: 30,
      baseHeight: 64,
      handleWidth: 18,
      handleHeight: 120,
      handleStrokeColor: '#4a4a4a',
      handleFillColor: '#e7e7e7',
    },
    ruler: {
      padding: 20,
      fontSize: "1.4em",
      stroke: "#333",
      strokeWidth: 3,
      contentPadding: 10,
      fallbackContentWidth: 300,
      edgeTickLength: 25,
      precision: 2,
    }
  },
};

export const ThemeContext = createContext({
  theme: themes.default, // default value
  labels: false,
  ruler: false,
  toggleTheme: () => {console.log("yeeee")}
});