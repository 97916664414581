import React, {useState, useEffect } from 'react';
import _ from 'lodash';
import gridClass from '../components/gridClass';
import { getPanecpoFieldId, getcpoFieldId, getcpoContainerId, panePropsToCopy } from '../components/helper/unicpoHelper';


function useCopyFromForm(selectedGrid, setSelectedGrid){
  const [hasChecked, setHasChecked] = useState(false);
  
  // load and parse uni cpo grid data
  // set selected grid with that data when available
  const onCPOSuccess = (event, data) => {  
    const { uni_cpo_object_data } = data
    const parsedcpoGridData = uni_cpo_object_data && uni_cpo_object_data !== "" ? JSON.parse(atob(uni_cpo_object_data)) : null;


    if (!parsedcpoGridData) {
      setHasChecked(true);
      return;
    }

    // default grid is set in windowlist comp
    // overwrite if present, generate from id and shape if not
    if(!selectedGrid){
      // if grid is not set, generate new one and merge
      const cpoGrid = new gridClass(parsedcpoGridData.id, parsedcpoGridData.shape);
      cpoGrid.generateDefaultFromShape();

      // https://lodash.com/docs/4.17.15#merge
      const newGrid = _.merge(cpoGrid, parsedcpoGridData);
      setSelectedGrid(newGrid);
    }else{
      // if it is set, merge with that
      const newGrid = _.merge(selectedGrid, parsedcpoGridData);
      setSelectedGrid(newGrid);
    }
    
    // unsubscribe from event after populating griddata
    window.jQuery(document.body).off('uni_cpo_options_data_ajax_success', onCPOSuccess)
    
    setHasChecked(true);
  }

  // get the grid data from unicpo when available
  useEffect(() => {
    const unicpoContainer = document.getElementById(getcpoContainerId())
    const objectInput = document.getElementById(getcpoFieldId("object_data"));

    // unicpo is present and name input (and by (hopefuly) extension all others) is not empty
    if (!unicpoContainer || !objectInput || !window.jQuery ) {
      console.warn("not in cpo environment");
      setHasChecked(true);
      return;
    } 
    
    // jquery adds own event layer -> jquery events are only caught with jquery event listeners 
    window.jQuery(document.body).on('uni_cpo_options_data_ajax_success', onCPOSuccess)

    // unsubscribe from even on unmount 
    return () => window.jQuery(document.body).on('uni_cpo_options_data_ajax_success', onCPOSuccess)
  }, []);

  
  return [hasChecked];
};

export default useCopyFromForm;