import React, { useState } from "react";
import ReactDOM from 'react-dom';

import './componentHelper.css';

export const RadioInput = ({ children, ...rest }) => {
  return (
    <label className="radioLabel">{children}
      <input className="radioInput" type="radio" {...rest} />
      <span className="radioCheckmark"></span>
    </label>
  )
}

export const ToggleInput = ({checked, handleChange, title, ...rest}) => {
  return (
    <div className="toggleInputWrapper">
      <label class="switch">
        <input type="checkbox" checked={checked} onChange={handleChange} {...rest} />
        <span class="slider round"></span>
      </label>
      {title && <span className="toggleInputTitle">{title}</span>}
    </div>
  )
}

export const Placeholder = () => (
  <p className="placeholder">
    Bitte wählen Sie zunächst einen Fenstertyp.
  </p>
)

export const Toggle = ({ title, children }) => {
  // TODO(Jan): Use CSSTransition and get actual height from ref
  const [state, setState] = useState("closed");

  const clickHandler = () => {
    const newState = state === "open" ? "closed" : "open";
    setState(newState);
  }


  return (
    <div className={`toggleWrapper ${state}`}>
      <div className="toggleTitle" onClick={clickHandler}>
        <h2>{title}</h2>
      </div>

      <div className={`toggleContent`}>{children}</div>
    </div>
  )
}


export const Portal = ({ children, DOMNodeID }) => {
  const element = document.getElementById(DOMNodeID);

  if (!element) {
    console.log("Element to Portal to not found");
    return <></>
  }

  return ReactDOM.createPortal(children, element);
}