import * as PANETYPES from '../paneTypes';

const defaultPaneProperties = {
  minWidth: 300,
  maxWidth: 2000,
  padding: {
    top: 80,
    right: 80,
    bottom: 80,
    left: 80,
  },
  margin: {
    top: 15,
    right: 15,
    bottom: 15,
    left: 15,
  },

}

const defaultRowProperties = {
  minHeight: 200,
  maxHeight: 2400,
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
}

const defaultWindowProperties = {
  minTotalHeight: 200,
  maxTotalHeight: 7500,
  minTotalWidth: 300,
  maxTotalWidth: 2800,
  padding: {
    top: 25,
    right: 25,
    bottom: 25,
    left: 25,
  },
  margin: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}

export const windowProperties = {
  ...defaultWindowProperties,
  nPanes: {
    1: {
      ...defaultWindowProperties,
      minTotalWidth: 400,
    },
    2: {
      ...defaultWindowProperties
    },
    3: {
      ...defaultWindowProperties,
      maxTotalWidth: 3800
    }
  }
}

export const rowProperties = {
  ...defaultRowProperties
}

export const paneProperties = {
  [PANETYPES.TYPE_FIXED]:
  {
    name: "Fest",
    ...defaultPaneProperties,
    minWidth: 300,
    maxWidth: 500,
    padding: {
      top: 40,
      right: 40,
      bottom: 40,
      left: 40,
    }
  },

  [PANETYPES.TYPE_FIXED_CASEMENT]:
  {
    name: "Fester Flügel",
    ...defaultPaneProperties,
  },

  [PANETYPES.TYPE_TILT]:
  {
    name: "Kipp",
    ...defaultPaneProperties,
  },

  [PANETYPES.TYPE_TURN_LEFT_POST]:
  {
    name: "Dreh Links (Pfosten)",
    ...defaultPaneProperties,
  },

  [PANETYPES.TYPE_TURN_LEFT_SASH]:
  {
    name: "Dreh Links (Stulp)",
    ...defaultPaneProperties,
    margin: {
      ...defaultPaneProperties.margin,
      right: 5,
    },
  },

  [PANETYPES.TYPE_TURN_TILT_LEFT]:
  {
    name: "Dreh-Kipp Links (Pfosten)",
    ...defaultPaneProperties,
  },
  [PANETYPES.TYPE_TURN_TILT_LEFT_SASH]:
  {
    name: "Dreh-Kipp Links (Stulp)",
    ...defaultPaneProperties,
    margin: {
      ...defaultPaneProperties.margin,
      right: 5,
    },
  },

  [PANETYPES.TYPE_TURN_RIGHT_POST]:
  {
    name: "Dreh Rechts (Pfosten)",
    ...defaultPaneProperties,
  },

  [PANETYPES.TYPE_TURN_RIGHT_SASH]:
  {
    name: "Dreh Rechts (Stulp)",
    ...defaultPaneProperties,
    margin: {
      ...defaultPaneProperties.margin,
      left: 5,
    },
  },

  [PANETYPES.TYPE_TURN_TILT_RIGHT]:
  {
    name: "Dreh-Kipp Rechts (Pfosten)",
    ...defaultPaneProperties,
  },
  [PANETYPES.TYPE_TURN_TILT_RIGHT_SASH]:
  {
    name: "Dreh-Kipp Rechts (Stulp)",
    ...defaultPaneProperties,
    margin: {
      ...defaultPaneProperties.margin,
      left: 5,
    },
  },
}
