import React from 'react';
import './App.css';

import Testcomp from './Testcomp';


function App() {

  return (
    <div className="App">
      <div className="content">
        {/* <WindowForm formDataCallback={setFormData} /> */}
        {/* props are passed to svg element */}
        {/* // TODO(Jan): handle empty grid data */}
        {/*  gridData && <WindowSketch gridData={gridData} />  */}
        <Testcomp />
      </div>
    </div>
  );
}

export default App;
