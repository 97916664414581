import React from 'react';

import WindowSketch from './WindowSketch';

const WindowListItem = ({ gridData, selectedGrid, setSelectedGrid, labels = false, title, scale = 1 }) => {
  // factor with which the container size grows with an increase in panes in the HF row 
  // if i understand that previous comment in the future i'd be very surprised
  // TODO(Jan): Maybe move this to theme?
  const displayWidthFactor = (gridData.shape[1] - 1) * 0.4 + 1;
  const displayWidth = scale * 126 * displayWidthFactor;

  const handleClick = (e) => {
    setSelectedGrid(gridData)
  }

  const bSelected = gridData && selectedGrid && gridData.id === selectedGrid.id;

  return (
    <div className={`windowListSVGContainer ${bSelected ? "active" : ""}`} onClick={(e) => { handleClick(e) }} style={{ width: displayWidth }}>
      <WindowSketch gridData={gridData} labels={labels} />
      <div className="windowListTitle">{title || gridData.name}</div>
    </div>
  )
}

export default WindowListItem;