import React, { useEffect, useState } from 'react';
import _, { cloneDeep, round } from 'lodash';

const getPane = (rowIndex, paneIndex, gridData) => {
  return gridData?.rows?.[rowIndex].panes?.[paneIndex];
}

const getRow = (rowIndex, gridData) => {
  return gridData?.rows?.[rowIndex]
}


const DimensionSelection = ({ selectedGrid, setSelectedGrid }) => {
  // HACK(Jan): Sometimes still produces invalid sketch drawings -> not checking the error existence properly -> Made the single row/pane values uneditable
  const defaultTotalWidth = selectedGrid.totalWidth;
  const defaultTotalHeight = selectedGrid.totalHeight;

  const defaultPaneWidths = selectedGrid.rows.map((row, rowInd) => {
    return row.panes.map((pane, paneInd) => {
      return pane.xEnd - pane.xStart;
    })
  })

  const defaultRowHeights = selectedGrid.rows.map((row, rowInd) => {
    return row.height;
  })

  const [totalWidth, setTotalWidth] = useState(defaultTotalWidth);
  const [totalHeight, setTotalHeight] = useState(defaultTotalHeight);
  const [paneWidths, setPaneWidths] = useState([...defaultPaneWidths]);
  const [rowHeights, setRowHeights] = useState([...defaultRowHeights]);
  const [errors, setErrors] = useState({});

  // TODO(Jan): Refactor the shared logic between handleTotalWidthChange and handlePaneWidthChange
  const handleTotalWidthChange = (e, valueRef, selectedGridRef, errorsRef, paneWidthsRef) => {
    const name = e.target.name;
    const value = parseFloat(e.target.value) || valueRef;

    let tempWidths = paneWidthsRef || cloneDeep(paneWidths);
    let tempErrors = errorsRef || cloneDeep(errors);
    let tempGrid = selectedGridRef || cloneDeep(selectedGrid);

    setTotalWidth(value);

    // go through all error conditions
    if (!value) {
      _.set(tempErrors, name, "Wert eingeben!")
      setErrors(() => ({ ...tempErrors }))
    } else if (value < tempGrid.minTotalWidth) {
      _.set(tempErrors, name, "zu klein!")
      setErrors(() => ({ ...tempErrors }))
    } else if (value > tempGrid.maxTotalWidth) {
      _.set(tempErrors, name, "zu groß!")
      setErrors(() => ({ ...tempErrors }))
    } else {
      _.unset(tempErrors, name);
      setErrors(() => ({ ...tempErrors }));
    }

    // TODO(Jan): Also do this in handleWidthChange
    // only propagate changes to the other width fields if this field is valid
    /* const noErrorsPreChange = _.every(tempErrors, _.isEmpty); */

    const noWidthErrorsPreChange = !tempErrors.totalWidth
    const noErrorsPreChange = _.every(tempErrors, _.isEmpty);
    if (noWidthErrorsPreChange) {
      // set new Widths to every pane in grid
      const oldTotalWidth = tempGrid.totalWidth;
      tempWidths.forEach((row, rowInd) => {
        row.forEach((paneWidth, paneInd) => {
          const newWidth = paneWidth / oldTotalWidth * value;
          console.log(paneInd);
          console.log({ paneWidth, oldTotalWidth, value, newWidth })
          handlePaneWidthChange(null, rowInd, paneInd, newWidth, tempGrid, tempErrors, tempWidths, 0);
        })
      })
    }

    // update the actual grid data
    const noWidthErrors = !tempErrors.totalWidth
    const noErrors = _.every(tempErrors, _.isEmpty);
    if (noWidthErrors) {
      tempGrid.totalWidth = value;
    }

    // pushing the accumulated changes to state
    setSelectedGrid(() => (tempGrid));
  }

  const handleTotalHeightChange = (e, valueRef, selectedGridRef, errorsRef, rowHeightsRef) => {
    const name = e.target.name;
    const value = parseFloat(e.target.value) || valueRef;

    let tempHeights = rowHeightsRef || cloneDeep(rowHeights);
    let tempErrors = errorsRef || cloneDeep(errors);
    let tempGrid = selectedGridRef || cloneDeep(selectedGrid);

    setTotalHeight(value);

    // go through all error conditions
    if (!value) {
      _.set(tempErrors, name, "Wert eingeben!")
      setErrors(() => ({ ...tempErrors }))
    } else if (value < tempGrid.minTotalHeight) {
      _.set(tempErrors, name, "zu klein!")
      setErrors(() => ({ ...tempErrors }))
    } else if (value > tempGrid.maxTotalHeight) {
      _.set(tempErrors, name, "zu groß!")
      setErrors(() => ({ ...tempErrors }))
    } else {
      _.unset(tempErrors, name);
      setErrors(() => ({ ...tempErrors }));
    }

    const noHeightErrorsPreChange = !tempErrors.totalHeight
    if (noHeightErrorsPreChange) {
      // set new heights to every pane in grid
      const oldTotalHeight = tempGrid.totalHeight;
      tempHeights.forEach((rowHeight, rowInd) => {
        const newHeight = rowHeight / oldTotalHeight * value;
        handleRowHeightChange(null, rowInd, newHeight, tempGrid, tempErrors, tempHeights, 0);
      })
    }

    // update the actual grid data
    const noHeightErrors = !tempErrors.totalHeight
    const noErrors = _.every(tempErrors, _.isEmpty);
    if (noHeightErrors) {
      tempGrid.totalHeight = value;
    }

    // pushing the accumulated changes to state
    setSelectedGrid(() => (tempGrid));
  }

  const handleRowHeightChange = (e, rowIndex, valueRef, selectedGridRef, errorsRef, rowHeightsRef, depth = 1) => {
    const name = e?.target?.name || `${rowIndex}`;
    const value = parseFloat(e?.target?.value) || valueRef;

    let tempHeights = rowHeightsRef || cloneDeep(rowHeights);
    let tempErrors = errorsRef || cloneDeep(errors);
    let tempGrid = selectedGridRef || cloneDeep(selectedGrid);

    const currentRow = getRow(rowIndex, tempGrid);
    const topNeighbour = getRow(rowIndex - 1, tempGrid);
    const bottomNeighbour = getRow(rowIndex + 1, tempGrid);

    // implement all the different error conditions!
    // prepend rowHeights to the path to add all the rowHeight related errors like this:
    /* 
    errors: {
      rowHeights: ["error 1", "error 2"]
      paneWidths: [
        ["error 1.1", "error 1.2"],
        ["error 2.1"]
      ]
    }
    */
    // this is to prevent collision with the paneWidths errors 
    const rowHeightsErrorPath = "rowHeights." + name;
    if (!value) {
      _.setWith(tempErrors, rowHeightsErrorPath, "Wert fehlt!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else if (value < currentRow.minHeight) {
      _.setWith(tempErrors, rowHeightsErrorPath, "Reihe zu klein!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else if (value > currentRow.maxHeight) {
      _.setWith(tempErrors, rowHeightsErrorPath, "Pane zu groß!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else {
      _.unset(tempErrors, rowHeightsErrorPath);
      setErrors(() => ({ ...tempErrors }));
    }

    // update neighboring fields
    if (depth > 0 && bottomNeighbour) {
      // sum all widths except the one of the right neighbour -> right neighbour will take up available space
      _.set(tempHeights, name, value)
      const sumNoBN = _.sum(tempHeights.filter((_, index) => (index !== rowIndex + 1)));
      // TODO(Jan): Possibe bug: is totalHeight properly up to date?
      const BNValue = totalHeight - sumNoBN;
      handleRowHeightChange(null, rowIndex + 1, BNValue, tempGrid, tempErrors, tempHeights, depth - 1);
    }

    if (depth > 0 && !bottomNeighbour && topNeighbour) {
      // same but with left neighbour
      _.set(tempHeights, name, value)
      const sumNoTN = _.sum(tempHeights.filter((_, index) => (index !== rowIndex - 1)));
      const TNValue = totalHeight - sumNoTN;
      handleRowHeightChange(null, rowIndex - 1, TNValue, tempGrid, tempErrors, tempHeights, depth - 1);
    }

    // BUG(Jan): When editing single pane rows its super buggy, need to look into this
    // HACK(JAN): temporarily (maybe permanently?) disabled inputs without neighbours (single pane rows)
    if (depth > 0 && !bottomNeighbour && !topNeighbour) {
      // single pane in row, just use the totalWidth
      _.set(tempHeights, name, totalHeight);
    }

    if (depth === 0) {
      // also update the widths when in lowest leveL
      // i.e. handling the neighbour change
      // good luck trying to follow this logic
      _.set(tempHeights, name, value)
    }

    // actually set the widths when all the (recursive) updating is done
    setRowHeights(() => ([...tempHeights]));

    // update the actual grid data
    // TODO(Jan): Check if we can prevent unnecessary execution of this (checking depth etc.)
    console.log(tempErrors);
    if (_.isEmpty(tempErrors?.rowHeights)) {
      let currentY = 0;
      tempGrid.rows.forEach((row, rowInd) => {
        tempGrid.rows[rowInd].yStart = currentY
        tempGrid.rows[rowInd].yEnd = currentY + tempHeights[rowInd]
        currentY = tempGrid.rows[rowInd].yEnd;
      })
    }

    // pushing the accumulated changes to state
    setSelectedGrid(() => (tempGrid));
  }

  const handlePaneWidthChange = (e, rowIndex, paneIndex, valueRef, selectedGridRef, errorsRef, paneWidthsRef, depth = 1) => {
    const name = e?.target?.name || `${rowIndex}.${paneIndex}`;
    const value = parseFloat(e?.target?.value) || valueRef;

    let tempWidths = paneWidthsRef || cloneDeep(paneWidths);
    let tempErrors = errorsRef || cloneDeep(errors);
    let tempGrid = selectedGridRef || cloneDeep(selectedGrid);

    const currentPane = getPane(rowIndex, paneIndex, tempGrid);
    const leftNeighbour = getPane(rowIndex, paneIndex - 1, tempGrid);
    const rightNeighbour = getPane(rowIndex, paneIndex + 1, tempGrid);

    // implement all the different error conditions!
    // prepend paneWidths to the path to add all the paneWidth related errors like this:
    /*
    errors: {
      rowHeights: ["error 1", "error 2"]
      paneWidths: [
        ["error 1.1", "error 1.2"],
        ["error 2.1"]
      ]
    }
    */
    // this is to prevent collision with the rowHeight errors 
    const paneWidthsErrorPath = "paneWidths." + name;
    if (!value) {
      _.setWith(tempErrors, paneWidthsErrorPath, "Wert fehlt!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else if (value < currentPane.minWidth) {
      _.setWith(tempErrors, paneWidthsErrorPath, "Pane zu klein!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else if (value > currentPane.maxWidth) {
      _.setWith(tempErrors, paneWidthsErrorPath, "Pane zu groß!", Object);
      setErrors(() => ({ ...tempErrors }));
    } else {
      _.unset(tempErrors, paneWidthsErrorPath);
      setErrors(() => ({ ...tempErrors }));
    }

    // update neighboring fields
    if (depth > 0 && rightNeighbour) {
      // sum all widths except the one of the right neighbour -> right neighbour will take up available space
      _.set(tempWidths, name, value)
      const rowSumNoRN = _.sum(tempWidths[rowIndex].filter((_, index) => (index !== paneIndex + 1)));
      // TODO(Jan): Possibe bug: is totalWidth properly up to date?
      const RNValue = totalWidth - rowSumNoRN;
      handlePaneWidthChange(null, rowIndex, paneIndex + 1, RNValue, tempGrid, tempErrors, tempWidths, depth - 1);
    }

    if (depth > 0 && !rightNeighbour && leftNeighbour) {
      // same but with left neighbour
      _.set(tempWidths, name, value)
      const rowSumNoLN = _.sum(tempWidths[rowIndex].filter((_, index) => (index !== paneIndex - 1)));
      const LNValue = totalWidth - rowSumNoLN;
      handlePaneWidthChange(null, rowIndex, paneIndex - 1, LNValue, tempGrid, tempErrors, tempWidths, depth - 1);
    }

    // BUG(Jan): When editing single pane rows its super buggy, need to look into this
    // HACK(JAN): temporarily (maybe permanently?) disabled inputs without neighbours (single pane rows)
    if (depth > 0 && !rightNeighbour && !leftNeighbour) {
      // single pane in row, just use the totalWidth
      _.set(tempWidths, name, totalWidth);
    }

    if (depth === 0) {
      // also update the widths when in lowest leveL
      // i.e. handling the neighbour change
      // good luck trying to follow this logic
      _.set(tempWidths, name, value)
    }

    // actually set the widths when all the (recursive) updating is done
    setPaneWidths(() => ([...tempWidths]));

    // update the actual grid data
    // TODO(Jan): Check if we can prevent unnecessary execution of this (checking depth etc.)
    if (_.isEmpty(tempErrors?.paneWidths?.[rowIndex])) {
      let currentX = 0;
      tempGrid.rows[rowIndex].panes.forEach((pane, paneInd) => {
        tempGrid.rows[rowIndex].panes[paneInd].xStart = currentX;
        tempGrid.rows[rowIndex].panes[paneInd].xEnd = currentX + tempWidths[rowIndex][paneInd];
        currentX = tempGrid.rows[rowIndex].panes[paneInd].xEnd;
      })
    }

    // pushing the accumulated changes to state
    setSelectedGrid(() => (tempGrid));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  }

  // reset the form when the selected grid changes
  useEffect(() => {
    setTotalWidth(defaultTotalWidth);
    setTotalHeight(defaultTotalHeight);
    setPaneWidths([...defaultPaneWidths]);
    setRowHeights([...defaultRowHeights]);
    setErrors({});
  }, [selectedGrid.id]);

  return (
    <form className="formContainer" onSubmit={handleSubmit}>
      <span className="formGroupTitle totalWidth">Breite</span>
      <div className="formRow">
        <div className="formGroup">
          <label>Bereich: {selectedGrid.minTotalWidth} mm bis {selectedGrid.maxTotalWidth} mm</label>
          <input
            type="number"
            name={`totalWidth`}
            value={round(totalWidth, 2)}
            onChange={(e) => { handleTotalWidthChange(e) }}
          />
          <div className="formError">{errors?.totalWidth}</div>
        </div>
      </div>

      <span className="formGroupTitle totalHeight">Höhe</span>
      <div className="formRow">
        <div className="formGroup">
          <label>Bereich: {selectedGrid.minTotalHeight} mm bis {selectedGrid.maxTotalHeight} mm</label>
          <input
            type="number"
            name={`totalHeight`}
            value={round(totalHeight, 2)}
            onChange={(e) => { handleTotalHeightChange(e) }}
          />
          <div className="formError">{errors?.totalHeight}</div>
        </div>
      </div>

      {selectedGrid.rows.map((row, rowInd) => (
        <div className="formRowGroup" key={rowInd}>
          <span className="formGroupTitle">{row.name}</span>
          <div className="formRow">
            <div className="formGroup">
              <span className="formInputTitle height">Höhe</span>
              <label>{row.minHeight} mm bis {row.maxHeight} mm</label>
              <input
                type="number"
                name={`${rowInd}`}
                value={round(_.get(rowHeights, `${rowInd}`), 2)}
                onChange={(e) => { handleRowHeightChange(e, rowInd) }}
                disabled={selectedGrid?.rows?.length <= 1 ? "disabled" : ""}
              />
              <div className="formError">{errors?.rowHeights?.[rowInd]}</div>
            </div>
          </div>

          <div key={rowInd} className="formRow">
            {row.panes.map((pane, paneInd) => {
              return (
                <div key={rowInd.toString() + "_" + paneInd.toString()} className="formGroup">
                  <span className="formInputTitle width">Breite</span>
                  <label>{pane.minWidth} mm bis {pane.maxWidth} mm</label>
                  <input
                    type="number"
                    name={`${rowInd}.${paneInd}`}
                    value={round(_.get(paneWidths, `${rowInd}.${paneInd}`), 2)}
                    onChange={(e) => { handlePaneWidthChange(e, rowInd, paneInd) }}
                    disabled={selectedGrid?.rows?.[rowInd]?.panes?.length <= 1 ? "disabled" : ""}
                  />
                  <div className="formError">{errors?.paneWidths?.[rowInd]?.[paneInd]}</div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </form>
  )
}


export default DimensionSelection;