// the props need to share the same name as the input field id (which is defined in unicpo)
// alternatively, we could do a mapping of the prop names -> unicpo field name here
// height mapped to hoehe or something like that 
export function getPanecpoFieldId(name, ind) {
  return `uni_cpo_${name}_${ind + 1}-field`
}

export function getcpoFieldId(name) {
  return `uni_cpo_${name}-field`;
}

export function getcpoContainerId() {
  return 'uni_cpo_options';
}

export const panePropsToCopy = [
  "name",
  "id",
  "height",
  "width",
  "type"
];