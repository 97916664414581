import {
  TYPE_FIXED,
  TYPE_FIXED_CASEMENT,
  TYPE_TILT,
  TYPE_TURN_TILT_LEFT,
  TYPE_TURN_TILT_LEFT_SASH,
  TYPE_TURN_LEFT_POST,
  TYPE_TURN_LEFT_SASH,
  TYPE_TURN_TILT_RIGHT,
  TYPE_TURN_TILT_RIGHT_SASH,
  TYPE_TURN_RIGHT_POST,
  TYPE_TURN_RIGHT_SASH,
} from './paneTypes';


export default {
  "0-1-0": [
    [[TYPE_FIXED]],
    [[TYPE_FIXED_CASEMENT]],
    [[TYPE_TILT]],
    [[TYPE_TURN_LEFT_POST]],
  ],
  "0-2-0": [
    [[TYPE_FIXED,TYPE_FIXED]],
    [[TYPE_FIXED_CASEMENT,TYPE_FIXED_CASEMENT]],
    [[TYPE_TILT,TYPE_TILT]],
  ],
  "2-2-2":[
    [
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_FIXED, TYPE_FIXED],
    ],
    [
      [TYPE_TILT, TYPE_TILT],
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_FIXED, TYPE_FIXED],
    ],
    [
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_TILT, TYPE_TILT],
      [TYPE_FIXED, TYPE_FIXED],
    ],
    [
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_FIXED, TYPE_FIXED],
      [TYPE_TILT, TYPE_TILT],
    ],
  ]
}