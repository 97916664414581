import _ from 'lodash';
import * as PANETYPES from '../constants/paneTypes';
import { paneProperties, rowProperties, windowProperties } from '../constants/properties';


// the margin/padding "system"
// space between panes (ie the posts), and generally all around the panes, is the panes margin (in all four directions)
// where there is no post (on outer panes (left most pane, right most pane, top/bottom if there are no othere rows there)), this margin still applies
// the margin is then part of the outer frame 
// frame thickness = padding window + margin pane

class gridPane {
  constructor(paneIndex, xStart, xEnd, type, parent) {
    this.id = `${parent.rowIndex}_${paneIndex}`;
    this.name = `Flügel ${paneIndex + 1}`;
    this.paneIndex = paneIndex;
    this.xStart = xStart;
    this.xEnd = xEnd;
    this.type = type;
    this.parent = parent;
  }

  get typeName() {
    return paneProperties[this.type].name;
  }

  get outerBox() {
    // vertical directions
    // always includes pane margin and row offset
    // if edge: window offset

    const topOffset = !this.neighbours.top 
      ? this.parent.totalOffset.top + this.margin.top 
      : this.parent.rowOffset.top + this.margin.top;

    const bottomOffset = !this.neighbours.bottom 
      ? this.parent.totalOffset.bottom + this.margin.bottom
      : this.parent.rowOffset.bottom + this.margin.bottom;
    
    // horizontal directions
    // always include pane margin
    // if edge: window offset and row offset
    const leftOffset = !this.neighbours.left
      ? this.parent.totalOffset.left + this.margin.left
      : this.margin.left;

    const rightOffset = !this.neighbours.right
      ? this.parent.totalOffset.right + this.margin.right
      : this.margin.right;

    return {
      xStart: this.xStart + leftOffset,
      xEnd: this.xEnd - rightOffset,
      yStart: this.yStart + topOffset,
      yEnd: this.yEnd - bottomOffset,
    }
  }

  get outerWidth() {
    return this.outerBox.xEnd - this.outerBox.xStart;
  }

  get outerHeight() {
    return this.outerBox.yEnd - this.outerBox.yStart;
  }

  get outerCenter() {
    return {
      x: this.outerBox.xStart + (this.outerWidth / 2 ),
      y: this.outerBox.yStart + (this.outerHeight / 2)
    }
  }

  get innerBox() {
    return {
      xStart: this.outerBox.xStart + this.padding.left,
      xEnd: this.outerBox.xEnd - this.padding.right,
      yStart: this.outerBox.yStart + this.padding.top,
      yEnd: this.outerBox.yEnd - this.padding.bottom,
    }
  }

  get innerWidth() {
    return this.innerBox.xEnd - this.innerBox.xStart;
  }

  get innerHeight() {
    return this.innerBox.yEnd - this.innerBox.yStart;
  }

  get innerCenter() {
    return {
      x: this.innerBox.xStart + (this.innerWidth / 2),
      y: this.innerBox.yStart + (this.innerHeight / 2),
    }
  }

  get neighbours() {
    return {
      top: this.parent.topNeighbour,
      right: this.rightNeighbour,
      bottom: this.parent.bottomNeighbour,
      left: this.leftNeighbour,
    }
  }

  get leftNeighbourCount() {
    if(this.leftNeighbour){
      return this.leftNeighbour.leftNeighbourCount + 1;
    }else {
      return 0;
    }
  }

  get rightNeighbourCount() {
    if (this.rightNeighbour) {
      return this.leftNeighbour.rightNeighbourCount + 1;
    } else {
      return 0;
    }
  }

  get topNeighbourCount() {
    if(this.parent.topNeighbour){
      return this.parent.topNeighbour.topNeighbourCount + 1
    } else {
      return 0;
    }
  }

  get bottomNeighbourCount() {
    if (this.parent.bottomNeighbour) {
      return this.parent.bottomNeighbour.bottomNeighbourCount + 1
    } else {
      return 0;
    }
  }

  get leftNeighbour() {
    return this.parent.panes[this.paneIndex - 1];
  }

  get rightNeighbour() {
    return this.parent.panes[this.paneIndex + 1];
  }

  get margin() {
    return paneProperties[this.type].margin;
  }

  get padding() {
    return paneProperties[this.type].padding;
  }

  get yStart() {
    return this.parent.yStart;
  }

  get yEnd() {
    return this.parent.yEnd;
  }

  get height() {
    return this.yEnd - this.yStart;
  }

  get width() {
    return this.xEnd - this.xStart;
  }

  get center() {
    return {
      x: this.xStart + (this.width / 2),
      y: this.yStart + (this.height / 2)
    }
  }

  get minWidth() {
    return paneProperties[this.type].minWidth;
  }

  get maxWidth() {
    return paneProperties[this.type].maxWidth
  }

}

class gridRow {
  constructor(name, rowIndex, yStart, yEnd, parent){
    this.name = name;
    this.rowIndex = rowIndex;
    this.yStart = yStart;
    this.yEnd = yEnd;
    this.panes = [];
    this.minHeight = rowProperties.minHeight;
    this.maxHeight = rowProperties.maxHeight;
    this.padding = rowProperties.padding;
    this.margin = rowProperties.margin;
    this.parent = parent;
  }

/*   get outerBox() {

  }

  get innerBox() {

  } */

  get rowOffset() {
    return {
      top: this.margin.top + this.padding.top,
      right: this.margin.right + this.padding.right,
      bottom: this.margin.bottom + this.padding.bottom,
      left: this.margin.left + this.padding.left,
    }
  }

  get totalOffset() {
    const {top: parentTop, right: parentRight, bottom: parentBottom, left: parentLeft } = this.parent.totalOffset;

    return {
      top: this.margin.top + this.padding.top + parentTop,
      right: this.margin.right + this.padding.right + parentRight,
      bottom: this.margin.bottom + this.padding.bottom + parentBottom,
      left: this.margin.left + this.padding.left + parentLeft,
    }
  }

  get topNeighbour() {
    return this.parent.rows[this.rowIndex - 1];
  }

  get bottomNeighbour() {
    return this.parent.rows[this.rowIndex + 1];
  }

  get topNeighbourCount() {
    if (this.topNeighbour) {
      return this.topNeighbour.topNeighbourCount + 1
    } else {
      return 0;
    }
  }

  get bottomNeighbourCount() {
    if (this.bottomNeighbour) {
      return this.bottomNeighbour.bottomNeighbourCount + 1
    } else {
      return 0;
    }
  }

  get width() {
    return _.sum(_.map(this.panes,'width'))
  }

  get height() {
    return this.yEnd - this.yStart;
  }

  get center() {
    return {
      x: this.parent.totalWidth / 2,
      y: this.yStart + (this.height / 2),
    }
  }

  get minWidth(){
    return _.sumBy(this.panes, (pane) => pane.minWidth);
  }

  get maxWidth() {
    return _.sumBy(this.panes, (pane) => pane.maxWidth);
  }

}


class gridClass {
  constructor(id,shape) {
    this.id = id;
    this.shape = shape;
    this.nPanes = _.sum(shape);
    this.name = this.constructor.generateNamesFromShape(shape).join(", ");
    this.rows = [];
    this.padding = windowProperties.padding;
    this.margin = windowProperties.margin;
  }

  static generateNamesFromShape = (shape) => {
    const nameList = [
      ["", "Oberlicht Einerteilung", "Oberlicht Zweierteilung", "Oberlicht Dreierteilung"],
      ["", "Einflügliges Fenster", "Zweiflügiges Fenster", "Dreiflügiges Fenster"],
      ["", "Unterlicht Einerteilung", "Unterlicht Zweierteilung", "Unterlicht Dreierteilung"]
    ];
    return shape.map((val, ind) => (nameList[ind][val])).filter((row) => (row !== ""))
  }

  generateDefaultFromShape() {

    // default values
    // TODO(Jan): "outsource" place for defaults and constants (like default dimensions and layout)
    const paneHeight = 1200;
    // ratio between paneheight of an oberlicht or unterlicht to the hauptfenster
    const paneHeightRatio = 0.4;
    const paneWidth = 1050;

    // generalize this to n rowed shapes
    const [nOL, nHF, nUL] = this.shape;
    // boolified
    const bOL = nOL > 0 ? true : false;
    const bHF = nHF > 0 ? true : false;
    const bUL = nUL > 0 ? true : false;

    // total height
    // true == 1, false == 0, kinda dirty
    this.totalHeight = paneHeight * bHF + paneHeight * paneHeightRatio * bOL + paneHeight * paneHeightRatio * bUL;

    // total width
    // generate pane width based on the max number of panes in a row -> min length is guaranteed
    /* const totalWidth = paneWidth * Math.max(...shape); */
    // generate pane width based on the number of panes in the hauptfenster row -> all windows of the same number of hauptfenster have the same width
    this.totalWidth = paneWidth * nHF;

    // the layout array then adds the row specific data
    const layout = [
      {
        name: "Oberlicht",
        paneHeightRatio,
        n: nOL,
        bExists: bOL,
      },
      {
        name: "Hauptfenster",
        paneHeightRatio: 1,
        n: nHF,
        bExists: bHF,
      },
      {
        name: "Unterlicht",
        paneHeightRatio,
        n: nUL,
        bExists: bUL,
      }
    ];

    let currentY = 0;
    layout.filter((row) => (row.bExists)).forEach((row, rowInd) => {
      const name = row.name;
      const yStart = currentY;
      const yEnd = currentY + paneHeight * row.paneHeightRatio
      const currentRow = new gridRow(name, rowInd, yStart, yEnd, this);

      currentY = yEnd;

      let currentX = 0;
      const paneWidth = this.totalWidth / row.n;
      const paneInd = [...Array(row.n).keys()];
      paneInd.forEach((paneInd) => {
        const xStart = currentX;
        const xEnd = currentX + paneWidth;
        const type = PANETYPES.TYPE_FIXED_CASEMENT;

        const currentPane = new gridPane(paneInd, xStart, xEnd, type, currentRow);

        currentX = xEnd;
        currentRow.panes.push(currentPane)
      })
      this.rows.push(currentRow);
    })
  }

  get paneTypesName() {
    return this.rows.map((row, rowIndex) => {
      return row.name + ": " + row.panes.map((pane,paneIndex) => {
        return paneProperties[pane.type].name;
      }).join(" - ");
    }).join(" | ");
  }

  get shapeHash() {
    return this.shape.join("-");
  }

  get flattendPanes() {
    return this.rows.reduce((acc, currentVal) => {
      acc.push(...currentVal.panes)
      return acc;
    }, [])
  }

  get outerBox() {
    return {
      xStart: this.margin.left,
      xEnd: this.totalWidth - this.margin.right,
      yStart: this.margin.top,
      yEnd: this.totalHeight - this.margin.bottom,
    }
  }

  get innerBox() {
    return {
      xStart: this.outerBox.xStart + this.padding.left,
      xEnd: this.outerBox.xEnd - this.padding.right,
      yStart: this.outerBox.yStart + this.padding.top,
      yEnd: this.outerBox.yEnd - this.padding.bottom,
    }
  }

  get totalOffset() {
    return {
      top: this.margin.top + this.padding.top,
      right: this.margin.right + this.padding.right,
      bottom: this.margin.bottom + this.padding.bottom,
      left: this.margin.left + this.padding.left,
    }
  }

  get maxPanesPerRow() {
    return _.max(this.shape);
  }

  get nRows(){
    return this.rows.length;
  }

  get nPanesOpenable(){
    return this.flattendPanes.filter((pane) => (pane.type !== PANETYPES.TYPE_FIXED) && (pane.type !== PANETYPES.TYPE_FIXED_CASEMENT))?.length
  }

  // TODO(Jan): Implement validation function
  // min and max as defined
  get minFixedWidth() {
    return windowProperties.nPanes[this.maxPanesPerRow].minTotalWidth;
  }

  get maxFixedWidth() {
    return windowProperties.nPanes[this.maxPanesPerRow].maxTotalWidth;
  }

  get minFixedHeight() {
    return windowProperties.minTotalHeight;
  }

  get maxFixedHeight(){
    return windowProperties.maxTotalHeight;
  }

  // min and max as the sum of panes
  get minPanesWidth() {
    return _.maxBy(this.rows, (row) => row.minWidth).minWidth;
  }

  get maxPanesWidth() {
    return _.minBy(this.rows, (row) => row.maxWidth).maxWidth;
  }

  get minRowsHeight() {
    return _.sumBy(this.rows, (row) => row.minHeight);
  }

  get maxRowsHeight() {
    return _.sumBy(this.rows, (row) => row.maxHeight);
  }

  // get min from both sum of panes and fixed properties
  get minTotalWidth(){
    return _.max([this.minFixedWidth, this.minPanesWidth]);
  }

  get maxTotalWidth(){
    return _.min([this.maxFixedWidth, this.maxPanesWidth]);
  }

  get minTotalHeight(){
    return _.max([this.minFixedHeight, this.minRowsHeight]);
  }

  get maxTotalHeight(){
    return _.min([this.maxFixedHeight,this.maxRowsHeight]);
  }
}

export default gridClass;